.extLeadForm-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.extLeadForm form {
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  max-width: 480px;
}

.extLeadForm label {
  display: none;
}

.extLeadForm input,
.extLeadForm select {
  padding: 8px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #000;
  margin: 0;
  border-radius: 0;
  transition: box-shadow 0.2s ease-in-out;
}

.extLeadForm input:focus,
.extLeadForm input:active,
.extLeadForm select:focus,
.extLeadForm select:active {
  border-radius: 0;
  box-shadow: 0 0 0 1px #000;
  outline: none;
}

.extLeadForm input[type="submit"] {
  background-color: #000;
  color: #fff;
  padding: 8px 25px;
  cursor: pointer;
  width: auto;
  border-radius: 0;
  border: 1px solid #000;
}

.extLeadForm input[type="submit"]:hover {
  background-color: #fff;
  color: #000;
  border: 1px solid #000;
}

@media (max-width: 600px) {
  .extLeadForm {
    width: 100%;
  }
}
